import { levels, levelToRole } from '../../service-plan/tools/enums';
import { sleep } from '@/utils/helpers';
import Store from '@/store';

const subscription = {
  choose: 'subscription.choose',
  free: 'subscription.confirm.free',
  paid: 'subscription.paid',
};

const FormRules = {
  required: (v) => !!v || 'Field is required',
};

/**
 * Updates user profile in a loop and looks for needed role
 *
 * @param {*} ctx Instance of component, usually injected
 * @param {String} plan Plan level, ex. LEVEL-1
 */
const roleCheckLoop = async (ctx, plan) => {
  let attempt = 0;

  const test = () => {
    return !Array.isArray(ctx.$store.getters.roles)
      || !ctx.$store.getters.roles.some((role) => role.Code === levelToRole[plan]);
  };

  while (attempt < 10 && test()) {
    ctx.paymentInfo = `Waiting for confirmation...#${++attempt}`;
    // eslint-disable-next-line no-await-in-loop
    const newProfile = await ctx.$store.dispatch('USER_REQUEST');

    if (test()) {
      // eslint-disable-next-line no-await-in-loop
      await sleep(1000 * 5);
    }
  }
  if (test() && attempt == 10) {
    ctx.paymentInfo = '$vuetify.alerts.request_error';
    ctx.loading = false;
    return false;
  }
  ctx.paymentInfo = 'Your payment was successfully processed.';
  ctx.paymentLink = { path: process.env.VUE_APP_DEFAULT_REDIRECT };
  ctx.loading = false;
  ctx.sent = true;
  return true;
};

const preSubscriptionRedirect = (ctx, targetSubscription, force) => {
  switch (targetSubscription) {
    case levels.LEVEL0:
      if (ctx.$route.name !== subscription.free || force) {
        ctx.$router.push({ name: subscription.free });
      }
      break;
    case levels.LEVEL1:
    case levels.LEVEL2:
      if (ctx.$route.name !== subscription.paid || force) {
        ctx.$router.push({ name: subscription.paid, params: { plan: targetSubscription } });
      }
      break;
    default:
      if (ctx.$route.name !== subscription.choose || force) {
        ctx.$router.push({ name: subscription.choose });
      }
  }
};

const forceSubscriptionRedirect = (to, from, next, targetSubscription) => {
  let store = Store;

  if (Object.values(subscription).includes(to.name) || store.getters.hasSubscribed) {
    return next();
  }

  switch (targetSubscription) {
    case levels.LEVEL0:
      if (to.name !== subscription.free) {
        return next({ name: subscription.free });
      }
      return next();

    case levels.LEVEL1:
    case levels.LEVEL2:
      if (to.name !== subscription.paid) {
        return next({ name: subscription.paid, params: { plan: targetSubscription } });
      }
      return next();

    default:
      if (to.name !== subscription.choose) {
        return next({ name: subscription.choose });
      }
      return next();
  }
};


export { subscription, FormRules, roleCheckLoop, preSubscriptionRedirect, forceSubscriptionRedirect };
